import React, {
    useCallback,
    useState,
    useMemo,
} from 'react'

import Card from 'app/shared-components/Card'
import Button from 'app/shared-components/Button'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import useContainerManagementRoutes from 'app/Apps/ContainerManagement/hooks/useContainerManagementRoutes'
import ButtonContainer from 'app/shared-components/ButtonContainer'
import {
    LaneGroupTemperatureRange,
} from 'app/types/enums'

import useBookContainers from '../../hooks/useBookContainers'
import requestConfig from '../../longTermLease.request'

import getConfig from './availableContainers.config'
import useStyles from './AvailableContainers.styles'

const AvailableContainers = ({
    id,
    onSuccess,
    tempRange,
}: {
    id: number,
    onSuccess: () => void,
    tempRange: LaneGroupTemperatureRange,
}) => {
    const {
        classes,
    } = useStyles()

    const [
        value,
        setValue,
    ] = useState<number[]>([])

    const {
        Containers: ContainersRoutes,
    } = useContainerManagementRoutes()

    const {
        mutate,
        isPending: isLoading,
    } = useBookContainers(onSuccess)

    const tableConfig = useMemo(() => {
        return getConfig({
            availableContainersLinkConfig: {
                path: ContainersRoutes.Overview.path,
                params: {
                    key: 'serialNumber',
                },
            },
        })
    }, []) // eslint-disable-line

    const onSelectRow = useCallback((selectedIds: number[]) => {
        setValue(selectedIds)
    }, [])

    const addToDedicated = () => {
        mutate({
            id,
            data: value?.map((containerId) => {
                return {
                    id: containerId,
                }
            }),
        })
    }

    return (
        <Card
            contentClass={classes.wrapper}
            titleClass={classes.title}
            title="Available Containers"
        >
            <SkyNetTable
                name="ContainerView"
                selectedRows={value}
                onSelectRow={onSelectRow}
                simple
                uniqField="id"
                customUrl={requestConfig.GetAvailableContainers.url}
                className={classes.root}
                rowsPerPageDefault={250}
                tableConfig={tableConfig}
                filter={{
                    tempRange: tempRange ? [tempRange] : undefined,
                }}
                showSearch
            />
            <ButtonContainer>
                <Button
                    label="add to dedicated containers"
                    onClick={addToDedicated}
                    disabled={!value.length}
                    saving={isLoading}
                    data-testid="btn-dedicated-containers"
                />
            </ButtonContainer>
        </Card>
    )
}

export default AvailableContainers
