import {
    LaneGroupTemperatureRange,
    LeaseType,
    LocationType,
    ProductType,
    AddressCategory, LaneStatus,
} from 'app/types/enums'
import {
    DTOCardFormField, FieldInCard, NestedField,
} from 'app/types/form.types'
import {
    DeliveryServiceType, TransportModeRoad, CollServiceType,
} from 'app/Apps/OrderManagement/Lanes/lanes.types'
import {
    Country,
} from 'app/Apps/ContactManagement/Countries/Countries.types'
import {
    SizeLink, TypeLink,
} from 'app/shared-components/SkyNetLink'

import {
    AccountLane, AccountLaneProductTypes,
} from '../AccountLanes.types'

type AccountLaneFormFields = AccountLane & AccountLaneProductTypes

const getFields = ({
    getAllowedValues,
    showFTL,
    showLTL,
    isDeliveryRoad,
    isCollection,
    handoverPointCountry,
    collectionDropoffPointCountry,
    showProductTypes,
    showAwardedProductTypes,
    exists,
    quoteInLink,
    quoteInNumber,
}: {
    getAllowedValues: (val: Record<string, string>) => Record<string, string>,
    showFTL: boolean,
    showLTL: boolean,
    isDeliveryRoad: boolean,
    isCollection: boolean,
    handoverPointCountry?: Country | {id: number},
    collectionDropoffPointCountry?: Country | {id: number},
    showProductTypes?: boolean,
    showAwardedProductTypes?: boolean,
    exists: boolean,
    quoteInLink?: string,
    quoteInNumber?: string,
}): DTOCardFormField < keyof AccountLaneFormFields>[] => {
    const generalFields: FieldInCard<keyof AccountLaneFormFields>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'laneNumber',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.laneNumber',
                    disabled: true,
                },
                {
                    name: 'opportunity',
                    componentName: 'OpportunitiesSelector',
                    labelKey: 'AccountLane.opportunity',
                    required: true,
                },
                {
                    name: 'account',
                    componentName: 'AccountSelector',
                    labelKey: 'AccountLane.accountCompanyName',
                    disabled: true,
                },
                ...exists ? [{
                    name: 'contractBasisAccount',
                    componentName: 'AccountSelector',
                    labelKey: 'Prebooking.pricingAccount',
                    disabled: true,
                }] as NestedField<keyof AccountLaneFormFields>[] : [],
                {
                    name: 'temperatureRange',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneGroupTemperatureRange),
                    labelKey: 'AccountLane.temperatureRange',
                    disabled: true,
                },
                {
                    name: 'leaseType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LeaseType),
                    labelKey: 'AccountLane.leaseType',
                    required: true,
                    showInColumns: true,
                },
                {
                    name: 'originAirport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.AIRPORT,
                    labelKey: 'AccountLaneForm.originAirport',
                    required: true,
                },
                {
                    name: 'destinationAirport',
                    componentName: 'LocationObjectSelector',
                    filterLocationType: LocationType.AIRPORT,
                    labelKey: 'AccountLaneForm.destinationAirport',
                    required: true,
                },
                ...exists ? [{
                    name: 'aQuoteId',
                    componentName: 'SkyNetLink',
                    link: quoteInLink,
                    text: quoteInNumber,
                    type: TypeLink.EXTERNAL,
                    size: SizeLink.S,
                    labelKey: 'AccountLaneForm.quotedIn',
                }] as NestedField<keyof AccountLaneFormFields>[] : [],
            ],
        },
        {
            id: 'column2',
            fields: [
                {
                    name: 'laneStatus',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(LaneStatus),
                    labelKey: 'LaneQuoteRequest.selectedLaneStatus',
                    disabled: true,
                },
                {
                    name: 'expPalletsYear',
                    required: true,
                    componentName: 'InputSingleline',
                    labelKey: 'LaneBasicView.expPalletsYear',
                },
                {
                    name: 'customerExecution',
                    componentName: 'LocationObjectSelector',
                    labelKey: 'LaneQuoteRequest.selectedCustomerExecution',
                    filterLocationType: LocationType.ADDRESS,
                    filterAddressCategory: [
                        AddressCategory.CUSTOMER,
                        AddressCategory.CUSTOMER_QUOTATION,
                    ],
                },
                {
                    name: 'customerExecutionProvisional',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.customerExecutionProvisional',
                },
                {
                    name: 'customerExecutionContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.customerExecutionContacts',
                },
                {
                    name: 'consigneeProvisional',
                    componentName: 'InputSingleline',
                    labelKey: 'AccountLane.consigneeProvisional',
                },
                {
                    name: 'consigneeContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.consigneeContacts',
                },
                {
                    name: 'laneComment',
                    componentName: 'LongText',
                    labelKey: 'AccountLane.laneComment',
                },
            ],
        },
    ]

    const transportModeRoad = {
        name: 'transportModeRoad',
        labelKey: 'LaneGroupCreateOrDuplicateDto.transportModeRoad',
        componentName: 'EnumSelector',
        allowedValues: getAllowedValues(TransportModeRoad),
        showInColumns: true,
        required: true,
    } as FieldInCard<keyof AccountLaneFormFields>

    const isFtlPositioningTemperatureControlled = {
        name: 'isFtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isFtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<keyof AccountLaneFormFields>

    const isLtlPositioningTemperatureControlled = {
        name: 'isLtlPositioningTemperatureControlled',
        labelKey: 'AccountLane.isLtlPositioningTemperatureControlled',
        componentName: 'Checkbox',
    } as FieldInCard<keyof AccountLaneFormFields>

    let transportModeFields = [transportModeRoad]

    if (showLTL) {
        transportModeFields = [
            ...transportModeFields,
            isLtlPositioningTemperatureControlled,
        ]
    }

    if (showFTL) {
        transportModeFields = [
            ...transportModeFields,
            isFtlPositioningTemperatureControlled,
        ]
    }

    const productTypesFields: FieldInCard<keyof AccountLaneFormFields>[] = [{
        id: 'column1',
        fields: [{
            name: 'requestedProductTypes',
            componentName: 'EnumChipSelector',
            allowedValues: getAllowedValues(ProductType),
            labelKey: 'AccountLane.requestedProductTypes',
            disabled: true,
        }],
    }]

    if (showAwardedProductTypes) {
        productTypesFields.push({
            id: 'column2',
            fields: [{
                name: 'awardedProductTypes',
                componentName: 'EnumChipSelector',
                allowedValues: getAllowedValues(ProductType),
                labelKey: 'AccountLane.awardedProductTypes',
                disabled: true,
            }],
        })
    }

    const handoverFields: FieldInCard<keyof AccountLaneFormFields>[] = [
        {
            id: 'column1',
            fields: [
                {
                    name: 'deliveryServiceType',
                    componentName: 'EnumSelector',
                    allowedValues: getAllowedValues(DeliveryServiceType),
                    labelKey: 'AccountLane.deliveryServiceType',
                    required: true,
                },
                ...(isDeliveryRoad
                    ? transportModeFields
                    : []) as NestedField<keyof AccountLaneFormFields>[],
            ],
        },
        {
            id: 'column2',
            fields: [
                ...(isDeliveryRoad ? [
                    {
                        name: 'handoverPointCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'AccountLane.handoverPointCountry',
                        required: true,
                    },
                    {
                        name: 'handoverPointZipCode',
                        componentName: 'ZipCodeInput',
                        labelKey: 'AccountLane.handoverPointZipCode',
                        required: true,
                        country: handoverPointCountry,
                    },
                    {
                        name: 'handoverPointAddress',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.handoverPointAddress',
                    },
                ] as NestedField<keyof AccountLaneFormFields>[] : []),
                {
                    name: 'handoverPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.handoverPointContacts',
                },
            ],
        },
    ]

    const collectionFields: FieldInCard<keyof AccountLaneFormFields>[] = [
        {
            id: 'column1',
            fields: [{
                name: 'collectionServiceType',
                componentName: 'EnumSelector',
                allowedValues: getAllowedValues(CollServiceType),
                labelKey: 'AccountLane.collectionServiceType',
                required: true,
            }],
        },
        {
            id: 'column2',
            fields: [
                ...(isCollection ? [
                    {
                        name: 'collectionDropoffPointCountry',
                        componentName: 'CountryDataSelector',
                        labelKey: 'AccountLane.collectionDropoffPointCountry',
                        required: true,
                    },
                    {
                        name: 'collectionDropoffPointZipCode',
                        componentName: 'ZipCodeInput',
                        labelKey: 'AccountLane.collectionDropoffPointZipCode',
                        required: true,
                        country: collectionDropoffPointCountry,
                    },
                    {
                        name: 'collectionDropoffPointAddress',
                        componentName: 'InputSingleline',
                        labelKey: 'AccountLane.collectionDropoffPointAddress',
                    },
                ] as NestedField<keyof AccountLaneFormFields>[] : []),
                {
                    name: 'collectionDropoffPointContacts',
                    componentName: 'MultipleContactObjectSelector',
                    labelKey: 'AccountLane.collectionDropoffPointContacts',
                },
            ],
        },
    ]

    const defaultSections = [
        {
            id: 'general',
            title: 'General',
            className: 'general',
            fields: generalFields,
        },
        {
            id: 'handover',
            title: 'Handover',
            className: 'handover',
            fields: handoverFields,
        },
        {
            id: 'collection',
            title: 'Collection',
            className: 'collection',
            fields: collectionFields,
        },
    ]

    return showProductTypes ? [
        ...defaultSections,
        {
            id: 'productTypes',
            title: 'Product Types',
            className: 'productTypes',
            fields: productTypesFields,
        },
    ] : defaultSections
}

export default getFields
