/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import InputSingleline from 'app/shared-components/InputSingleline'
import {
    Validate,
} from 'app/types/form.types'
import useValidateItem from '../../hooks/useValidateItem'

type Props = {
    title?: string,
    textType?: string,
    name?: string,
    onChange: (...args: any[]) => void,
    value?: string | number,
    className?: string,
    min?: number,
    validate?: Validate,
    setErrors?: (e: [string, any[]]) => void
}

const defaultProps = {
    title: undefined,
    textType: undefined,
    name: undefined,
    value: undefined,
    className: undefined,
    min: undefined,
    validate: undefined,
    setErrors: undefined,
}

const IntegerInput = (props: Props) => {
    const {
        validate,
        value,
        setErrors,
        name,
    } = props

    const {
        errors, validated,
    } = useValidateItem({
        validate, value, setFormError: setErrors, key: name,
    })

    return (
        <InputSingleline
            {...props}
            type="number"
            errors={errors}
            validated={validated}
        />
    )
}

IntegerInput.defaultProps = defaultProps

export default IntegerInput
