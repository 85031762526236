import {
    RequestConfig,
} from 'app/types/request.types'

type MarginsRequests = {
    filterRequest: () => RequestConfig
}

const requests: MarginsRequests = {
    filterRequest: () => {
        return {
            url: 'marginratecard/filter-request/all',
        }
    },
}

export default requests
