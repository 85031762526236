import React from 'react'
import noop from 'lodash/noop'

import useGetCountry from 'app/Apps/ContactManagement/Countries/hooks/useGetCountry'
import useValidateItem from 'app/hooks/useValidateItem'
import {
    Validate,
} from 'app/types/form.types'
import {
    TypeaheadObjectContainer,
} from '../Typeahead'
import useCountryRequests from './hooks/useCountryRequests'

type Props = {
    title?: string,
    value?: { id: number | string },
    onChange?: (...args: any[]) => void,
    className?: string,
    required?: boolean,
    disabled?: boolean,
    validate?: Validate,
    setErrors?: (e: [string, any[]]) => void,
    name?: string,
}
const defaultProps: Partial<Props> = {
    title: '',
    name: '',
    value: null,
    onChange: noop,
    className: '',
    required: false,
    disabled: false,
    validate: undefined,
    setErrors: undefined,
}

// accepts country as object and returns an object onChange
// will be the only selector after dataModel elimination

const CountryDataSelector = ({
    title,
    value,
    onChange,
    className,
    required,
    disabled,
    validate,
    setErrors,
    name,
}: Props) => {
    const {
        loadOptions,
    } = useCountryRequests()

    const {
        data,
    } = useGetCountry(value?.id)

    const {
        errors,
    } = useValidateItem({
        validate, value: data, setFormError: setErrors, key: name,
    })

    const showValidation = validate ? Boolean(validate(data)) : false

    return (
        <div className={className}>
            <TypeaheadObjectContainer
                title={title}
                onChange={onChange}
                loadOptions={loadOptions}
                labelData={data}
                isRequired={required}
                disabled={disabled}
                labelField="countryName"
                value={value?.id}
                variant="standard"
                errors={errors}
                validated={showValidation}
            />
        </div>
    )
}

CountryDataSelector.defaultProps = defaultProps

export default CountryDataSelector
