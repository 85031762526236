import {
    RequestConfig,
} from 'app/types/request.types'

type LogisticsCostsRequests = {
    filterRequest: () => RequestConfig
}

const requests: LogisticsCostsRequests = {
    filterRequest: () => {
        return {
            url: 'logisticscostsratecard/filter-request/all',
        }
    },
}

export default requests
