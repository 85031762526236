import React, {
    useMemo,
} from 'react'
import {
    Navigate,
    useLocation,
} from 'react-router-dom'
import {
    CommonRoutes, SkyNetRouteConfigObject,
    useSkyNetRoutes,
} from 'app/SkyNetRoutes'
import {
    RateCardsTabs,
} from '../RateCards.types'

import BaseLeaseBulkEdit from '../BaseLease/BaseLeaseBulkEdit'
import OtherLaneFeesBulkEdit from '../OtherLaneFees/OtherLaneFeesBulkEdit'
import AdditionalFeesBulkEdit from '../AdditionalFees/AdditionalFeesBulkEdit'
import useRatesRoutes from '../../hooks/useRatesRoutes'

export default function RateCardsBulkEdit() {
    const {
        state,
    } = useLocation()

    const {
        RateCards: RateCardsRoutes,
    } = useRatesRoutes()

    const routesConfig: SkyNetRouteConfigObject<RateCardsTabs> = useMemo(() => {
        return {
            [RateCardsTabs.BaseLease]: <BaseLeaseBulkEdit onClose={
                RateCardsRoutes.BaseLease.navigate
            }
            />,
            [RateCardsTabs.OtherLaneFees]: <OtherLaneFeesBulkEdit onClose={
                RateCardsRoutes.OtherLaneFees.navigate
            }
            />,
            [RateCardsTabs.AdditionalFees]: <AdditionalFeesBulkEdit onClose={
                RateCardsRoutes.AdditionalFees.navigate
            }
            />,
            [RateCardsTabs.Margins]: <div>Margins Bulk Edit</div>,
            [RateCardsTabs.EmbargoCountries]: <div>Embargo Countries Bulk Edit</div>,
            [CommonRoutes.SLASH]: (
                <Navigate
                    to={
                        state?.from.split(CommonRoutes.SLASH).at(-1)
                        || RateCardsRoutes.BaseLease.route
                    }
                    replace
                />
            ),
        }
    }, [
        state?.from,
        RateCardsRoutes,
    ])

    return useSkyNetRoutes({
        routesConfig,
    })
}
