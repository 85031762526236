import {
    Validate,
} from 'app/types/form.types'

export const checkNegativeNumber: Validate<number> = (value: number, errorMsg) => {
    return value <= 0 ? [errorMsg || 'Only positive numbers are allowed'] : undefined
}

export const isNegativeNumber = (fieldName: string, errorMsg?: string) => {
    return (value: Record<string, any>) => {
        return checkNegativeNumber(value?.[fieldName], errorMsg)
    }
}
