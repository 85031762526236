import React, {
    useMemo,
} from 'react'

import SkyNetTable from 'app/shared-components/SkyNetTable'
import SkyNetDomain, {
    SidebarTab,
} from 'app/shared-components/SkyNetDomain'
import useHasPermission from 'app/hooks/useHasPermission'
import useTabsByPermission from 'app/hooks/useTabsByPermission'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import {
    RequestKeys,
} from 'app/hooks/useRequest'

import useContainerManagementRoutes from '../hooks/useContainerManagementRoutes'

import getTableConfig from './serviceCenters.config'
import ServiceCenterCreate from './ServiceCenterCreate'
import ServiceCenterOverview from './ServiceCenterOverview'
import ServiceCenterUpdate from './ServiceCenterUpdate'
import Monitoring from './Monitoring'
import request from './serviceCenters.request'
import {
    ServiceCenterStatus,
} from './serviceCenters.types'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ShowArhived,
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const ServiceCenters = () => {
    const {
        ServiceCenters: ServiceCentersRoutes,
    } = useContainerManagementRoutes()
    const canCreate = useHasPermission(['service_center_create'])
    const canUpdate = useHasPermission(['service_center_update'])
    const canMonitoring = useHasPermission(['service_center_monitoring'])
    const tabs: SidebarTab[] = useTabsByPermission([
        {
            tab: {
                url: ServiceCentersRoutes.Overview.route,
                label: 'Overview',
                Component: ServiceCenterOverview,
            },
            permission: true,
        },
        {
            tab: {
                url: ServiceCentersRoutes.Update.route,
                label: 'Data Update',
                Component: ServiceCenterUpdate,
            },
            permission: canUpdate,
        },
        {
            tab: {
                url: ServiceCentersRoutes.Monitoring.route,
                label: 'Monitoring',
                Component: Monitoring,
                condition: ({
                    serviceCenterStatus,
                }) => {
                    return serviceCenterStatus === ServiceCenterStatus.ACTIVE
                },
            },
            permission: canMonitoring,
        },
    ])
    const tableConfig = useMemo(() => {
        return getTableConfig()
    }, [])

    return (
        <SkyNetDomain
            defaultTab={ServiceCentersRoutes.Overview.route}
            name={tableConfig.name}
            getDomainObject={request.GetServiceCenterById}
            actionComponents={{
                Create: canCreate ? ServiceCenterCreate : undefined,
            }}
            sidebarTabs={tabs}
            domainRequestKey={RequestKeys.getServiceCenterById}
        >
            <SkyNetTable
                tableControls={tableControls}
                tableConfig={tableConfig}
                showSearch
            />
        </SkyNetDomain>

    )
}

export default ServiceCenters
