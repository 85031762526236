import {
    CellLocation,
    ReactGridProps,
    DefaultCellTypes, CellStyle, Column,
} from '@silevis/reactgrid'
import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'

import {
    Validate,
} from 'app/types/form.types'

import SkyNetSpreadSheet from './SkyNetSpreadSheet'
import {
    SelectCell,
    NoValueCell,
    IdCell,
    TypeaheadCell,
    DateTimeCell,
    MultipleSelectCell,
    HeaderFilterableCell,
    MultipleTypeaheadCell,
} from './customTemplates'

export enum SkyNetSpreadSheetCellType {
    TEXT = 'text',
    NUMBER = 'number',
    CHECKBOX = 'checkbox',
    PERCENTAGE = 'percentage',
    NO_VALUE = 'noValue',
    SELECT = 'select',
    TYPEAHEAD = 'typeahead',
    DATETIME = 'datetime',
    DATE = 'date',
    TIME = 'time',
    ID = 'id',
    HEADER = 'header',
    HEADER_FILTERABLE = 'header_filterable',
    MULTIPLE_SELECT = 'multiple_select',
    MULTIPLE_TYPEAHEAD = 'multiple_typeahead'
}

export type SkyNetSpreadSheetColumn = Column & {
    calcValue?: (...args: any[]) => any,
}

export type DefaultCell = {
    type: SkyNetSpreadSheetCellType,
    value?: number,
    text?: string,
    id?: number,
    onChange?: (...args: any) => any
}

export type CustomCellTypes = SelectCell | NoValueCell | IdCell | TypeaheadCell
    | DateTimeCell | MultipleSelectCell | HeaderFilterableCell | MultipleTypeaheadCell | DefaultCell

export type ExtendedCellTypes = DefaultCellTypes | CustomCellTypes
export enum Domain {
    AIRPORT = 'location/airport',
    ACCOUNT = 'account',
    COMPANY = 'company',
}

export enum DomainUIOnly {
    OPPOPTUNITY = 'opportunity',
    CONTACT = 'contact',
    COUNTRY = 'country',
    REGIONAL_GROUP = 'regional_group',
    LOCATION = 'location',
    USER = 'user'
}

type ConditionalType = [(...args: any[]) => boolean, SkyNetSpreadSheetCellType]

type DefaultCellConfig = {
    type: SkyNetSpreadSheetCellType.TEXT
}

export type OnChangeCellFunction = (value: any, rowItem?: Record<string, any>) =>
    Promise<Record<string, any> | undefined> | Record<string, any> | undefined

export type CellConfig<T = any> = {
    type: T
    conditionalType?: ConditionalType[],
    onChangeAutopopulate?: OnChangeCellFunction,
    onChangeOverride?: OnChangeCellFunction,
    validate?: Validate | Validate[],
    style?: CellStyle,
    headerStyle?: CellStyle,
}

type DateTimeCellConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.DATETIME>,
    format: string,
}

type SelectDefinedOptionsCellConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.SELECT>
        | CellConfig<SkyNetSpreadSheetCellType.MULTIPLE_SELECT>,
    allowedValues: Record<string, string> | [any, string],
    noneOption?: boolean,
}

type SelectGetOptionsCellConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.SELECT>
        | CellConfig<SkyNetSpreadSheetCellType.MULTIPLE_SELECT>,
    getAllowedValues: (...args: any[]) => Record<string, string> | [any, string][],
    noneOption?: boolean,
}

type TypeaheadCellConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.TYPEAHEAD
        | SkyNetSpreadSheetCellType.MULTIPLE_TYPEAHEAD>,
    domain: Domain | DomainUIOnly,
    loadOptions: (options: DelayedLoadOptions) => Promise<Record<string, any>[]>,
    applyFilters?: (item: Record<string, any>) => Record<string, any>,
    options: Promise<Record<string, any>[]> | Record<string, any>[],
}

type CheckboxCellConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.CHECKBOX>,
    renderYesNoSelect?: boolean
}

type TextFieldConfig = {
    cellConfig?: CellConfig<SkyNetSpreadSheetCellType.TEXT> | DefaultCellConfig
}

type NumberFieldConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.NUMBER>,
    format?: Intl.NumberFormat
}

type PercentageFieldConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.PERCENTAGE>
}

type IdFieldConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.ID>
}

type NoValueFieldConfig = {
    cellConfig: CellConfig<SkyNetSpreadSheetCellType.NO_VALUE>
}

type DefaultFields<F> = {
    name: F,
    title?: string,
    width?: number,
    hidden?: boolean,
    required?: boolean,
    disabled?: boolean | ((i: Record<string, any>) => boolean),
    reorderable?: boolean,
    resizable?: boolean,
    defaultValue?: any,
    calcValue?: (...args: any[]) => any,
}

export type SuperHeaderConfig = {
    height?: number,
    cells: {
        title: string,
        colspan?: number,
        style?: CellStyle
    }[]
}

export type CustomCellConfig = TypeaheadCellConfig | SelectGetOptionsCellConfig | IdFieldConfig
    | SelectDefinedOptionsCellConfig | DateTimeCellConfig | CheckboxCellConfig
    | PercentageFieldConfig | NumberFieldConfig | TextFieldConfig | NoValueFieldConfig

export type FieldConfig<F> = DefaultFields<F> & CustomCellConfig

export type SkyNetSpreadSheetConfigType<F> = Partial<ReactGridProps> & {
    id: string,
    title?: string,
    initialFocusLocation?: CellLocation,
    enableRangeSelection?: boolean,
    enableRowSelection?: boolean,
    enableColumnSelection?: boolean,
    lastEmptyRow?: boolean,
    superHeader?: SuperHeaderConfig[],
    headerRow?: boolean,
    rowHeight?: number,
    headerHeight?: number,
    addRowMenuOption?: boolean,
    maxHeight?: number,
    rowsCounter?: boolean,
    fields: FieldConfig<F>[],
    emptyRows?: number,
    filterable?: boolean,
    rowsLimit?: number,
}

export type SpreadsheetCellOption = {
    value?: number | string,
    label: string,
    id: number | string,
    textLabel?: string,
    search?: string
} & Record<string, any>

export type TypeaheadParams = {
    loadOptions: (options: DelayedLoadOptions) => Promise<SpreadsheetCellOption[]>,
    options: SpreadsheetCellOption[]
}

const Component = {
    SkyNetSpreadSheet,
}

type SkyNetSpreadSheetConfig<F> = {
    componentName: keyof typeof Component,
    config: SkyNetSpreadSheetConfigType<F>,
    filterInvalidate?: (...args: any[]) => Record<string, any>[],
    disabled: boolean,
    onChange: (...args: any[]) => void,
}

export default SkyNetSpreadSheetConfig
